// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import getRandomArbitraryInt from '@bodhi-project/components/lib/methods/getRandomArbitraryInt'
import Img from 'gatsby-image'

import ConfigProvider from 'antd/lib/config-provider'
import '@bodhi-project/antrd/lib/4.10.3/config-provider/style/css'

import { graphql } from 'gatsby'

import StandardPageWrapper from '../components/standard-page-wrapper'
import '../components/standard-page-wrapper/style.less'

import Link from '../components/link'
import '../components/link/style.less'

// import Cover from '../components/cover'
// import '../components/cover/style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import seoHelper from '../methods/seoHelper'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const pageData = {
  pageTitle: 'Homepage',
  nakedPageSlug: '',
  pageAbstract:
    'Prisma Dreams is about a future taking shape through courageous dreaming and imaginative bonding. Between the present and the unknown breaths an eternal freshness of experimentation. Between the old and the new lives a covenant of heritage and evolution. The path is every step a quantum leap. Prisma Dreams is therefore that laboratory of freedom and play between the past, present and future.',
}

const seoData = seoHelper(pageData)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    symbol1: file(relativePath: { eq: "symbol1.jpg" }) {
      ...max900
    }
    imgX: file(relativePath: { eq: "imgX.jpg" }) {
      ...max1500
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = props => (
  <ConfigProvider prefixCls="wtf">
    <StandardPageWrapper seoData={seoData} {...props}>
      <div className="responsive" style={{ position: 'relative' }}>
        <h1
          style={{
            position: 'absolute',
            bottom: '2.5rem',
            left: '1rem',
            zIndex: 100,
            color: '#fcf1d0',
            textShadow: '4px 4px 12px rgba(17.3, 17.6, 28.2, 0.5)',
            textAlign: 'center',
            width: '100%',
            maxWidth: 'unset',
            marginBottom: 0,
          }}
        >
          <big>
            Prisma
            <br />
            Dreams
          </big>
        </h1>
        <Img
          style={{
            borderRadius: '1rem',
            overflow: 'hidden',
            // boxShadow: '4px 4px 96px #ffa448',
          }}
          fluid={props.data.imgX.childImageSharp.fluid}
          durationFadeIn={getRandomArbitraryInt(150, 450)}
        />
      </div>
      <div className="rest">
        <div style={{ width: '100%', paddingLeft: '1rem' }}>
          <p
            style={{
              marginBottom: 0,
              textAlign: 'center',
              maxWidth: 'unset',
              // fontStyle: 'italic',
            }}
          >
            Prisma Dreams is about a future taking shape
            <br />
            through&nbsp;
            <u style={{ fontSize: 'inherit', color: 'inherit' }}>
              courageous dreaming
            </u>
            &nbsp;and&nbsp;
            <u style={{ fontSize: 'inherit', color: 'inherit' }}>
              imaginative bonding
            </u>
            .<br style={{ marginBottom: '0.38rem' }} />
            Between the present and the unknown
            <br />
            breathes an eternal freshness of experimentation.
            <br style={{ marginBottom: '0.38rem' }} />
            Between the old and the new
            <br />
            lives a covenant of heritage and evolution.
            <br style={{ marginBottom: '0.38rem' }} />
            The path is every step a quantum leap.
            <br style={{ marginBottom: '0.38rem' }} />
            Prisma Dreams is that&nbsp;
            <u style={{ fontSize: 'inherit', color: 'inherit' }}>
              laboratory of freedom and play
            </u>
            <br />
            between the past, present and future.
            <br style={{ display: 'block', marginTop: '1.14rem' }} />
            Explore the rest of our{' '}
            <Link to="https://www.prisma.haus">Prisma Haus</Link>
            &nbsp;–&nbsp;
            <br />
            <Link to="https://books.prisma.haus">Prisma Books</Link>
            &nbsp;•&nbsp;
            <Link to="https://design.prisma.haus">Prisma Design</Link>
            &nbsp;•&nbsp;
            <Link to="https://arts.prisma.haus">Prisma Arts</Link>
          </p>
          {/*
          <div
            className="as-paragraph"
            style={{
              paddingTop: '1rem',
              marginTop: '1rem',
              marginBottom: 0,
              maxWidth: 'unset',
            }}
          >
            <div
              style={{
                width: '5rem',
                height: '5rem',
                marginRight: 'auto',
              }}
            >
              <Img
                fluid={props.data.symbol1.childImageSharp.fluid}
                durationFadeIn={getRandomArbitraryInt(150, 450)}
              />
            </div>
          </div>
          */}
        </div>
      </div>
    </StandardPageWrapper>
  </ConfigProvider>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
